import React, { Suspense, lazy } from "react";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";

const LoginView = lazy(() => import(/*webpackChunkName: "login"*/ "./components/views/LoginView/LoginView"));
const Dashboard = lazy(() => import(/*webpackChunkName: "login"*/ "./components/views/DashboardView/DashboardView"));

const Router: React.FunctionComponent = () => <HashRouter>
    <Suspense fallback={<div />}>
        <Switch>
            <Redirect exact={true} from="/" to="login" />
            <Route path="/login" component={LoginView} />
            <Route path="/dashboard" component={Dashboard} />
        </Switch>
    </Suspense>
</HashRouter>;

export default Router;
